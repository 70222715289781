import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "admin-panel-nav-menu__wrap" }
const _hoisted_2 = { class: "admin-panel-nav-menu__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/admin/payments-log",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Payments Log")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/analytics/skills-tracking",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Analytics")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/instructors",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Instructors")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/students",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Students")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/stickers",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Stickers")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/skill-achievement-log",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Skill achievement log")
        ])),
        _: 1
      })
    ])
  ]))
}